import styled from 'styled-components';
import { motion } from 'framer-motion';

const CartoonButton = styled(motion.button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 4px solid #000;
  border-radius: 12px;
  box-shadow: 4px 4px 0 #000;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.2rem;
  padding: 10px 10px;
  cursor: pointer;
  margin: 20px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    animation: shake;
  }

  &:active {
    box-shadow: 2px 2px 0 #000;
    transform: translate(2px, 2px);
  }
`;

export default CartoonButton;
