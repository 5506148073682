import React, { useState } from 'react';
import styled from 'styled-components';


const InfoContainer = styled.div`
  margin-top: 20px;
  border: 4px solid #000;
  border-radius: 8px;
  box-shadow: 4px 4px 0 #000;
  padding: 20px;
  background-color: #fff;
`;

const ToggleButton = styled.button`
  border: 2px solid #000;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 8px 12px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1rem;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
  border-bottom: 2px dashed #000;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }

  @media(min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  flex: 1;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 2px solid #000;
    border-radius: 8px;
  }

  .social-links {
    margin-top: 10px;
    a {
      margin-right: 10px;
      font-size: 1.2rem;
      &:hover {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;

const RightSide = styled.div`
  flex: 2;
  padding-left: 0;

  @media(min-width: 768px) {
    padding-left: 20px;
  }

  h3 {
    margin: 0 0 5px 0;
  }

  p {
    margin: 5px 0;
  }
`;

export default function InfoPanels({ projects }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <InfoContainer>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Hide Info' : 'Show Info'}
      </ToggleButton>
      {isOpen && (
        <>
          {projects.map((project) => (
            <ProjectCard key={project.id}>
              <LeftSide>
                <img src={project.imgUrl} alt={`${project.title} Logo`} />
                <div className="social-links">
                  {project.socialLinks.map((link, idx) => (
                    <a key={idx} href={link.url} target="_blank" rel="noreferrer">
                      {link.icon}
                    </a>
                  ))}
                </div>
              </LeftSide>
              <RightSide>
                <h3>{project.title}</h3>
                <p>{project.description}</p>
                {/* Add more details or sub-info as needed */}
              </RightSide>
            </ProjectCard>
          ))}
        </>
      )}
    </InfoContainer>
  );
}

