
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it covers everything */
`;

const ModalContent = styled.div`
  background-color: #fff;
  width: 90%;
  max-width: 600px;
  padding: 30px;
  border-radius: 12px;
  border: 4px solid #000;
  box-shadow: 6px 6px 0 #000;
  font-family: ${({ theme }) => theme.fonts.primary || 'sans-serif'};
  color: ${({ theme }) => theme.colors.text || '#000'};
  text-align: left;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  margin-bottom: 20px;
  line-height: 1.5;
`;

const AcknowledgeButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary || '#ddd'};
  border: 2px solid #000;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.primary || 'sans-serif'};
  font-size: 1rem;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent || '#bbb'};
  }
`;

export default function DisclaimerModal({ onAcknowledge }) {
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>Disclaimer</ModalHeader>
        <ModalText>
          This tool is currently in alpha stage. It’s a free-to-use tool made by 
          <strong> $YEPE </strong> to tribute Matt Furie’s work. We are not affiliated 
          with Matt Furie beyond our love for his art. This tool is meant to bring fun 
          and collaboration to the Web3 community. If you want your project added to the 
          PFP generator, you can send a request to 
          <strong> info@yepecoin.xyz</strong>.
        </ModalText>
        <ModalText>
          By clicking the button below, you acknowledge and understand the alpha 
          status of this tool and agree to proceed.
        </ModalText>
        <AcknowledgeButton onClick={onAcknowledge}>
          Acknowledge
        </AcknowledgeButton>
      </ModalContent>
    </ModalOverlay>
  );
}
