import React, { createContext, useState } from 'react';
import {
  backgrounds,
  accessories,
  baseBodies,
  mouths,
  eyes,
  hats,
  hands,
} from '../assets/traits';

export const TraitsContext = createContext();

export const TraitsProvider = ({ children }) => {
  // Default traits
  const defaultBackground = backgrounds[0];
  const defaultAccessory = accessories[0];
  const defaultBaseBody = baseBodies[0];
  const defaultMouth = mouths[0];
  const defaultEyes = eyes[0];
  const defaultHat = hats[0];
  const defaultHand = hands[0];

  const [customBackground, setCustomBackground] = useState(null);

  const [selectedBackground, setSelectedBackground] = useState(defaultBackground);
  const [selectedAccessory, setSelectedAccessory] = useState(defaultAccessory);
  const [selectedBaseBody, setSelectedBaseBody] = useState(defaultBaseBody);
  const [selectedMouth, setSelectedMouth] = useState(defaultMouth);
  const [selectedEyes, setSelectedEyes] = useState(defaultEyes);
  const [selectedHat, setSelectedHat] = useState(defaultHat);
  const [selectedHand, setSelectedHand] = useState(defaultHand);

  const randomItem = (array) => array[Math.floor(Math.random() * array.length)];

  const randomizeTraits = () => {
    setSelectedBackground(randomItem(backgrounds));
    setSelectedAccessory(randomItem(accessories));
    setSelectedBaseBody(randomItem(baseBodies));
    setSelectedMouth(randomItem(mouths));
    setSelectedEyes(randomItem(eyes));
    setSelectedHat(randomItem(hats));
    setSelectedHand(randomItem(hands));
    // If you want to clear the custom background when randomizing, do it here:
    // setCustomBackground(null);
  };

  const resetTraits = () => {
    setSelectedBackground(defaultBackground);
    setSelectedAccessory(defaultAccessory);
    setSelectedBaseBody(defaultBaseBody);
    setSelectedMouth(defaultMouth);
    setSelectedEyes(defaultEyes);
    setSelectedHat(defaultHat);
    setSelectedHand(defaultHand);
    // Reset the custom background as well:
    setCustomBackground(null);
  };

  return (
    <TraitsContext.Provider
      value={{
        selectedBackground,
        setSelectedBackground,
        customBackground,
        setCustomBackground,
        selectedAccessory,
        setSelectedAccessory,
        selectedBaseBody,
        setSelectedBaseBody,
        selectedMouth,
        setSelectedMouth,
        selectedEyes,
        setSelectedEyes,
        selectedHat,
        setSelectedHat,
        selectedHand,
        setSelectedHand,
        randomizeTraits,
        resetTraits,
      }}
    >
      {children}
    </TraitsContext.Provider>
  );
};
