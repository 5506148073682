// Import images from assets folders

// Backgrounds
import YELLOW from './Backgrounds/YELLOW.png';
import CYAN from './Backgrounds/CYAN.png';
import PINK from './Backgrounds/PINK.png';
import ORANGE from './Backgrounds/ORANGE.png';
import RED from './Backgrounds/RED.png';
import GREEN from './Backgrounds/GREEN.png';
import BLUE from './Backgrounds/BLUE.png';
import NONE from './Backgrounds/NONE.png';

// Accessories
import SPIKE_ACC from './Accessories/SPIKE.png';
import NONE_ACC from './Accessories/NONE.png';

// Base Bodies
import BRETT_BODY from './BaseBodys/BRETT.png';
import YEPE_BODY from './BaseBodys/YEPE.png';

// Mouths
import ANDY_MOUTH from './Mouth/ANDY.png';
import BIRDDOG_MOUTH from './Mouth/BIRDDOG.png';
import BRETT_MOUTH from './Mouth/BRETT.png';
import DRAGGY_MOUTH from './Mouth/DRAGGY.png';
import FOFAR_MOUTH from './Mouth/FOFAR.png';
import PEPE_MOUTH from './Mouth/PEPE.png';
import SKULL_MOUTH from './Mouth/SKULL.png';
import SPIKE_MOUTH from './Mouth/SPIKE.png';
import WOLF_MOUTH from './Mouth/WOLF.png';
import YEPE_MOUTH from './Mouth/YEPE.png';
import HOPPY_MOUTH from './Mouth/HOPPY.png';

// Eyes
import ANDY_EYES from './Eyes/ANDY.png';
import BIRDDOG_EYES from './Eyes/BIRDDOG.png';
import BRETT_EYES from './Eyes/BRETT.png';
import DRAGGY_EYES from './Eyes/DRAGGY.png';
import FOFAR_EYES from './Eyes/FOFAR.png';
import PEPE_EYES from './Eyes/PEPE.png';
import SKULL_EYES from './Eyes/SKULL.png';
import SPIKE_EYES from './Eyes/SPIKE.png';
import WOLF_EYES from './Eyes/WOLF.png';
import YEPE_EYES from './Eyes/YEPE.png';
import FORG_EYES from './Eyes/FORG.png';
import HOPPY_EYES from './Eyes/HOPPY.png';

// Hats
import ANDY_HAT_FRONT from './Hats/ANDY_front.png';
import ANDY_HAT_BACK from './Hats/ANDY_back.png';
import BIRDDOG_HAT_FRONT from './Hats/BIRDDOG_front.png'; // Assuming no back image
import BIRDDOG_HAT_BACK from './Hats/BIRDDOG_back.png';
import FOFAR_HAT from './Hats/FOFAR.png';
import NONE_HAT from './Hats/NONE.png';

// Hands
import NONE_HAND from './Hands/NONE.png';
import SODA_HAND from './Hands/SODA.png';
import PHONE_HAND from './Hands/PHONE.png';
import BEBE_HAND from './Hands/BEBE.png';
import OK_HAND from './Hands/OK.png';
import METAL_HAND from './Hands/METAL.png';
import SMOKE_HAND from './Hands/SMOKE.png';
import BONG_HAND from './Hands/BONG.png';
import BEER_HAND from './Hands/BEER.png';
import PIZZA_HAND from './Hands/PIZZA.png';



export const backgrounds = [
    { name: 'YELLOW', image: YELLOW },
    { name: 'BLUE', image: BLUE },
    { name: 'GREEN', image: GREEN },
    { name: 'RED', image: RED },
    { name: 'ORANGE', image: ORANGE },
    { name: 'PINK', image: PINK },
    { name: 'CYAN', image: CYAN },
    { name: 'NONE', image: NONE },
    { name: 'CUSTOM', image: null },
  ];
  
  export const accessories = [
    { name: 'NONE', image: NONE_ACC },
    { name: 'SPIKE', image: SPIKE_ACC },
  ];
  
  export const baseBodies = [
    { name: 'YEPE', image: YEPE_BODY },
    { name: 'BRETT', image: BRETT_BODY },
  ];
  
  export const mouths = [
    { name: 'YEPE', image: YEPE_MOUTH },
    { name: 'ANDY', image: ANDY_MOUTH },
    { name: 'BIRDDOG', image: BIRDDOG_MOUTH },
    { name: 'BRETT', image: BRETT_MOUTH },
    { name: 'DRAGGY', image: DRAGGY_MOUTH },
    { name: 'FOFAR', image: FOFAR_MOUTH },
    { name: 'PEPE', image: PEPE_MOUTH },
    { name: 'SKULL', image: SKULL_MOUTH },
    { name: 'SPIKE', image: SPIKE_MOUTH },
    { name: 'WOLF', image: WOLF_MOUTH },
    { name: 'HOPPY', image: HOPPY_MOUTH },
  ];
  
  export const eyes = [
    { name: 'YEPE', image: YEPE_EYES },
    { name: 'ANDY', image: ANDY_EYES },
    { name: 'BIRDDOG', image: BIRDDOG_EYES },
    { name: 'BRETT', image: BRETT_EYES },
    { name: 'DRAGGY', image: DRAGGY_EYES },
    { name: 'FOFAR', image: FOFAR_EYES },
    { name: 'PEPE', image: PEPE_EYES },
    { name: 'SKULL', image: SKULL_EYES },
    { name: 'SPIKE', image: SPIKE_EYES },
    { name: 'WOLF', image: WOLF_EYES },
    { name: 'FORG', image: FORG_EYES },
    { name: 'HOPPY', image: HOPPY_EYES },
  ];
  

  
  export const hats = [
    { name: 'NONE', image: NONE_HAT },
    // For hats with both front and back images
    {
      name: 'ANDY',
      frontImage: ANDY_HAT_FRONT,
      backImage: ANDY_HAT_BACK,
      isLinked: true,
    },
    // For hats with only one image
    {
        name: 'BIRDDOG',
        frontImage: BIRDDOG_HAT_FRONT,
        backImage: BIRDDOG_HAT_BACK,
        isLinked: true,
      },
    { name: 'FOFAR', image: FOFAR_HAT },

  ];
  
  export const hands = [
    { name: 'NONE', image: NONE_HAND },
    { name: 'SODA', image: SODA_HAND },
    { name: 'PHONE', image: PHONE_HAND },
    { name: 'BEBE', image: BEBE_HAND },
    { name: 'OK', image: OK_HAND },
    { name: 'METAL', image: METAL_HAND },
    { name: 'SMOKE', image: SMOKE_HAND },
    { name: 'BONG', image: BONG_HAND },
    { name: 'BEER', image: BEER_HAND },
    { name: 'PIZZA', image: PIZZA_HAND },
  ];