import React, { useContext, useState } from 'react';
import TopMenu from './TopMenu';
import TraitSelector from './TraitSelector';
import PFPDisplay from './PFPDisplay';
import CartoonButton from './CartoonButton';
import RandomPFPMarquee from './RandomPFPMarquee';
import DownloadShare from './Downloadshare';
import SlotMachine from './SlotMachine';
import DisclaimerModal from './DisclaimerModal'; // <-- Import the new modal
import styled from 'styled-components';
import { TraitsContext } from '../context/TraitsContext';
import InfoPanels from './InfoPanels';
import { FaTwitter, FaGlobe, FaTelegramPlane } from 'react-icons/fa';

import YEPE_IMG from '../assets/images/YEPE.jpg';
import ANDY_IMG from '../assets/images/ANDY.jpg';
import BRETT_IMG from '../assets/images/BRETT.jpg';
import BIRDDOG_IMG from '../assets/images/BIRDDOG.jpg';
import DRAGGY_IMG from '../assets/images/DRAGGY.jpg';
import FOFAR_IMG from '../assets/images/FOFAR.jpg';
import FORG_IMG from '../assets/images/FORG.jpg';
import HOPPY_IMG from '../assets/images/HOPPY.jpg';
import LANDWOLF_IMG from '../assets/images/LANDWOLF.jpg';
import PEPE_IMG from '../assets/images/PEPE.jpg';
import SPIKE_IMG from '../assets/images/SPIKE.jpg';
import WOLFSKULL_IMG from '../assets/images/WOLFSKULL.jpg';


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media(min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 40px;
  }
`;

const PFPSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const TraitSelectorCard = styled.div`
  width: 100%;
  margin-top: 20px;

  @media(min-width: 768px) {
    width: 400px;
    margin-left: 40px;
    margin-top: 0;
  }

  background-color: #fff;
  border: 4px solid #000;
  border-radius: 12px;
  box-shadow: 5px 5px 0 #000;
  padding: 20px;
`;

export const projectsData = [
  {
    id: 'p1',
    title: 'YEPE',
    imgUrl: YEPE_IMG, // Use imported image
    socialLinks: [
      { url: 'https://www.yepecoin.xyz/', icon: <FaGlobe /> },
      { url: 'https://x.com/yepecoin', icon: <FaTwitter /> },
      { url: 'https://t.me/YepeCoinETH', icon: <FaTelegramPlane /> },
    ],
    description: 'YEPE, a tribute to Furie’s imaginative world, is an open invitation to explore memes, claymation storytelling, and user-generated fun on Ethereum. It’s a frog leading the charge in connected creativity and positive collaboration.',
  },
  {
    id: 'p2',
    title: 'ANDY',
    imgUrl: ANDY_IMG,
    socialLinks: [      
      { url: 'https://boysclubandy.com/', icon: <FaGlobe /> },
      { url: 'https://twitter.com/andycoinonerc', icon: <FaTwitter /> },
      { url: 'https://t.me/theonlyandyleft', icon: <FaTelegramPlane /> },
    ],
    description: 'Join Andy, a member of the famous Boys Club, in a world of memes, friends, and high-fiving like champs! The Andy community is growing every day, come join the fun.',
  },
  {
    id: 'p3',
    title: 'BRETT',
    imgUrl: BRETT_IMG,
    socialLinks: [      
      { url: 'https://www.basedbrett.com/', icon: <FaGlobe /> },
      { url: 'https://twitter.com/BasedBrett', icon: <FaTwitter /> },
      { url: 'https://t.me/basedbrett', icon: <FaTelegramPlane /> },
    ],
    description: 'Brett is the legendary character from Matt Furie’s Boys club comic. He has become the blue mascot of the blue chain',
  },
  {
    id: 'p4',
    title: 'BIRDDOG',
    imgUrl: BIRDDOG_IMG,
    socialLinks: [      
      { url: 'https://x.com/EthBirdDog', icon: <FaTwitter /> },
      { url: 'https://t.me/birddog70', icon: <FaTelegramPlane /> },
    ],
    description: 'Birddog0x70 CA one after Pepe! 5th member of the Boy’s Club',
  },
  {
    id: 'p5',
    title: 'DRAGGY',
    imgUrl: DRAGGY_IMG,
    socialLinks: [      
      { url: 'https://draggy.co/', icon: <FaGlobe /> },
      { url: 'https://x.com/DraggyCTO', icon: <FaTwitter /> },
      { url: 'https://t.me/DraggyOfficialCTO', icon: <FaTelegramPlane /> },
    ],
    description: 'Meet $Draggy, protector of Hoppy in The Night Riders. Draggy is a gentle giant that watches over his friends, while they learn to enjoy the company of each other on their adventure to the top.',
  },
  {
    id: 'p6',
    title: 'FOFAR',
    imgUrl: FOFAR_IMG,
    socialLinks: [      
      { url: 'https://www.fofar.io/', icon: <FaGlobe /> },
      { url: 'https://twitter.com/fofar_erc/', icon: <FaTwitter /> },
      { url: 'http://t.me/Fofar_ERC', icon: <FaTelegramPlane /> },
    ],
    description: 'Fofar is revolutionizing memecoins and decentralized gaming with the Meme Arcade platform. The Meme Super Community is coming.',
  },
  {
    id: 'p7',
    title: 'FORG',
    imgUrl: FORG_IMG,
    socialLinks: [      
      { url: 'https://forgbymattfurie.com/', icon: <FaGlobe /> },
      { url: 'https://x.com/forgbymattfurie', icon: <FaTwitter /> },
      { url: 'https://t.me/forgctoeth', icon: <FaTelegramPlane /> },
    ],
    description: 'Introducing the character inspired by Matt Furie, Forg brings a playful twist to the crypto landscape! Immerse yourself in a vibrant community where creativity meets finance, celebrating the joy and whimsy of meme culture.',
  },
  {
    id: 'p8',
    title: 'HOPPY',
    imgUrl: HOPPY_IMG,
    socialLinks: [      
      { url: 'https://hoppy.vip/', icon: <FaGlobe /> },
      { url: 'https://twitter.com/hoppycoinERC20', icon: <FaTwitter /> },
      { url: 'https://t.me/hoppycoinERC20', icon: <FaTelegramPlane /> },
    ],
    description: 'Hoppy is on a mission to take over the meme universe. The time has come for Hoppy to show his greatness.',
  },
  {
    id: 'p9',
    title: 'LANDWOLF',
    imgUrl: LANDWOLF_IMG,
    socialLinks: [
      { url: 'https://landwolf.com/', icon: <FaGlobe /> },
      { url: 'https://twitter.com/Wolf0x67', icon: <FaTwitter /> },
      { url: 'https://t.me/BoysClubWolf', icon: <FaTelegramPlane /> },
    ],
    description: 'Half man, half wolf. LANDWOLF, a Matt Furie creation unlike any that came before. He’s just a cool dude who loves to hang out with his friends, eat pizza, and play video games. Watch out for the full moon: that’s when Landwolf transforms into a party animal, roaming the streets looking for adventure.',
  },
  {
    id: 'p10',
    title: 'PEPE',
    imgUrl: PEPE_IMG,
    socialLinks: [
      { url: 'https://www.pepe.vip/', icon: <FaGlobe /> },
      { url: 'https://x.com/pepecoineth', icon: <FaTwitter /> },
    ],
    description: 'Pepe is here to make memecoins great again. Launched stealth with no presale, zero taxes, LP burnt and contract renounced, $PEPE is a coin for the people, forever. Fueled by pure memetic power, let $PEPE show you the way.',
  },
  {
    id: 'p11',
    title: 'SPIKE',
    imgUrl: SPIKE_IMG,
    socialLinks: [
      { url: 'https://www.spikefurie.com/', icon: <FaGlobe /> },
      { url: 'https://x.com/SpikeFurie', icon: <FaTwitter /> },
      { url: 'https://t.me/SpikeFurieOfficial', icon: <FaTelegramPlane /> },
    ],
    description: 'I’M SPIKE! The first drawing created by Matt Furie. An original character that came before Pepe, Brett and Hoppy. Drawn in 1984 by the Godfather of memes, Matt Furie.',
  },
  {
    id: 'p12',
    title: 'WOLFSKULL',
    imgUrl: WOLFSKULL_IMG,
    socialLinks: [
      { url: 'https://wolfskull.us/', icon: <FaGlobe /> },
      { url: 'https://x.com/WolfSkullERC20', icon: <FaTwitter /> },
      { url: 'https://t.me/WolfSkullOfficial', icon: <FaTelegramPlane /> },
    ],
    description: 'Wolfskull is Matt Furie’s most menacing creation, a main character straight from the pages of his latest comic book Mindviscosity. He’s back and more menacing with an intense appetite for meme characters – Wolfskull is here to cause havoc, bringing you next level eye candy and an unforgettable journey.',
  },
  // etc.
];

const App = () => {
  const {
    selectedBackground,
    customBackground,
    selectedAccessory,
    selectedBaseBody,
    selectedMouth,
    selectedEyes,
    selectedHat,
    selectedHand,
    randomizeTraits,
    resetTraits,
  } = useContext(TraitsContext);

  // *** New State for Disclaimer Modal ***
  const [showDisclaimer, setShowDisclaimer] = useState(
    !localStorage.getItem('disclaimerAccepted')
  );

  const [randomizing, setRandomizing] = useState(false);

  const handleRandomize = () => {
    setRandomizing(true);
    setTimeout(() => {
      randomizeTraits();
      setRandomizing(false);
    }, 2000);
  };

  const handleReset = () => {
    resetTraits();
  };

  const handleAcknowledge = () => {
    localStorage.setItem('disclaimerAccepted', 'true');
    setShowDisclaimer(false);
  };


  
  return (
    <>
      {/* Show the disclaimer only if showDisclaimer is true */}
      {showDisclaimer && (
        <DisclaimerModal onAcknowledge={handleAcknowledge} />
      )}

      {/* The main content is displayed behind the modal, but is inaccessible 
          until disclaimers is acknowledged */}
      <TopMenu />
      <RandomPFPMarquee />
      <AppContainer>
        <PFPSection>
          <PFPDisplay
            background={selectedBackground}
            customBackground={customBackground}
            accessory={selectedAccessory}
            baseBody={selectedBaseBody}
            mouth={selectedMouth}
            eyes={selectedEyes}
            hat={selectedHat}
            hand={selectedHand}
          />
          {randomizing && <SlotMachine />}
          <ControlsContainer>
            <CartoonButton onClick={handleRandomize}>🎰 Shuffle</CartoonButton>
            <CartoonButton onClick={handleReset}>🔄 Reset</CartoonButton>
            <DownloadShare />
          </ControlsContainer>
        </PFPSection>
        <TraitSelectorCard>
          <TraitSelector />
        </TraitSelectorCard>
      </AppContainer>
      <InfoPanels projects={projectsData} />
    </>
  );
};

export default App;
