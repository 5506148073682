import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { TraitsContext } from '../context/TraitsContext';
import {
  backgrounds,
  accessories,
  baseBodies,
  mouths,
  eyes,
  hats,
  hands,
} from '../assets/traits';

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
`;

const Tab = styled.button`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.secondary : theme.colors.primary};
  border: 2px solid #000;
  border-bottom: ${({ isActive }) => (isActive ? 'none' : '2px solid #000')};
  border-radius: 8px 8px 0 0;
  padding: 10px 20px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.1rem;
  margin: 0 5px;
  outline: none;
  position: relative;
  top: 2px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

const TraitList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const TraitButton = styled.button`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary : theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  border: 2px solid #000;
  border-radius: 8px;
  padding: 8px 12px;
  font-family: ${({ theme }) => theme.fonts.primary};
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 80px;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

const CustomBackgroundUploader = styled.div`
  margin-top: 10px;

  input[type='file'] {
    cursor: pointer;
  }

  p {
    margin-top: 5px;
    font-size: 0.9rem;
    color: green;
  }
`;

const TraitSelector = () => {
  const {
    selectedBackground,
    setSelectedBackground,
    customBackground,
    setCustomBackground,
    selectedAccessory,
    setSelectedAccessory,
    selectedBaseBody,
    setSelectedBaseBody,
    selectedMouth,
    setSelectedMouth,
    selectedEyes,
    setSelectedEyes,
    selectedHat,
    setSelectedHat,
    selectedHand,
    setSelectedHand,
  } = useContext(TraitsContext);

  const [activeTab, setActiveTab] = useState('Backgrounds');
  const fileInputRef = useRef(null);

  // Trait categories with references to context state setters
  const traitCategories = [
    {
      name: 'Backgrounds',
      traits: backgrounds,
      selectedTrait: selectedBackground,
      setSelectedTrait: setSelectedBackground,
    },
    {
      name: 'Accessories',
      traits: accessories,
      selectedTrait: selectedAccessory,
      setSelectedTrait: setSelectedAccessory,
    },
    {
      name: 'Base Bodies',
      traits: baseBodies,
      selectedTrait: selectedBaseBody,
      setSelectedTrait: setSelectedBaseBody,
    },
    {
      name: 'Mouths',
      traits: mouths,
      selectedTrait: selectedMouth,
      setSelectedTrait: setSelectedMouth,
    },
    {
      name: 'Eyes',
      traits: eyes,
      selectedTrait: selectedEyes,
      setSelectedTrait: setSelectedEyes,
    },
    {
      name: 'Hats',
      traits: hats,
      selectedTrait: selectedHat,
      setSelectedTrait: setSelectedHat,
    },
    {
      name: 'Hands',
      traits: hands,
      selectedTrait: selectedHand,
      setSelectedTrait: setSelectedHand,
    },
  ];

  const currentCategory = traitCategories.find(
    (category) => category.name === activeTab
  );

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleTraitSelect = (trait) => {
    currentCategory.setSelectedTrait(trait);
    // If the user picks a predefined background instead of CUSTOM, clear customBackground
    if (activeTab === 'Backgrounds') {
      if (trait.name !== 'CUSTOM') {
        setCustomBackground(null);
      }
    }
  };

  const handleCustomBackgroundUpload = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCustomBackground(reader.result); // Store Data URL in context
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <TabsContainer>
        {traitCategories.map((category) => (
          <Tab
            key={category.name}
            onClick={() => handleTabClick(category.name)}
            isActive={activeTab === category.name}
          >
            {category.name}
          </Tab>
        ))}
      </TabsContainer>

      <TraitList>
        {currentCategory.traits.map((trait) => (
          <TraitButton
            key={trait.name}
            onClick={() => handleTraitSelect(trait)}
            selected={currentCategory.selectedTrait.name === trait.name}
          >
            {trait.name}
          </TraitButton>
        ))}
      </TraitList>

      {/* If CUSTOM background is chosen, show file upload */}
      {activeTab === 'Backgrounds' &&
        currentCategory.selectedTrait &&
        currentCategory.selectedTrait.name === 'CUSTOM' && (
          <CustomBackgroundUploader>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleCustomBackgroundUpload}
            />
            {customBackground && (
              <p>Custom background uploaded successfully!</p>
            )}
          </CustomBackgroundUploader>
        )}
    </div>
  );
};

export default TraitSelector;
