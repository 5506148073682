import React, { useContext } from 'react';
import CartoonButton from './CartoonButton';
import {FaDownload } from 'react-icons/fa';
import styled from 'styled-components';
import { TraitsContext } from '../context/TraitsContext';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
`;

const DownloadShare = () => {
  const {
    selectedBackground,
    customBackground,
    selectedAccessory,
    selectedBaseBody,
    selectedMouth,
    selectedEyes,
    selectedHat,
    selectedHand,
  } = useContext(TraitsContext);

  const downloadImage = async () => {
    const width = 3308;
    const height = 3309;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    const loadImage = (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous'; // To avoid CORS issues
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Failed to load image: ' + src));
      });

    try {
      // 1) Handle Background
      let bgImage;
      if (selectedBackground.name === 'CUSTOM' && customBackground) {
        // Use the Data URL
        bgImage = await loadImage(customBackground);
      } else {
        bgImage = await loadImage(selectedBackground.image);
      }

      // 2) Load Other Images
      const accImage = await loadImage(selectedAccessory.image);
      const bodyImage = await loadImage(selectedBaseBody.image);
      const mouthImage = await loadImage(selectedMouth.image);
      const eyesImage = await loadImage(selectedEyes.image);

      // For hat back and front
      let hatBackImage = null;
      let hatFrontImage = null;
      if (selectedHat.isLinked && selectedHat.backImage) {
        hatBackImage = await loadImage(selectedHat.backImage);
      }
      if (selectedHat.frontImage) {
        hatFrontImage = await loadImage(selectedHat.frontImage);
      } else if (selectedHat.image) {
        hatFrontImage = await loadImage(selectedHat.image);
      }

      // Hand image
      const handImage = await loadImage(selectedHand.image);

      // 3) Draw them in the correct order
      // Background
      ctx.drawImage(bgImage, 0, 0, width, height);

      // Accessory
      ctx.drawImage(accImage, 0, 0, width, height);

      // Hat back part
      if (hatBackImage) {
        ctx.drawImage(hatBackImage, 0, 0, width, height);
      }

      // Base Body
      ctx.drawImage(bodyImage, 0, 0, width, height);

      // Eyes
      ctx.drawImage(eyesImage, 0, 0, width, height);

      // Mouth
      ctx.drawImage(mouthImage, 0, 0, width, height);

      // Hat front or single hat
      if (hatFrontImage) {
        ctx.drawImage(hatFrontImage, 0, 0, width, height);
      }

      // Hand
      ctx.drawImage(handImage, 0, 0, width, height);

      // 4) Convert to Data URL and download
      const dataURL = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'my_pfp.png';
      link.href = dataURL;
      link.click();
    } catch (error) {
      console.error('Error downloading image:', error);
      alert('Failed to download image. Please try again.');
    }
  };

  return (
    <ButtonContainer>
      <CartoonButton onClick={downloadImage}>
        <FaDownload /> Download
      </CartoonButton>
    </ButtonContainer>
  );
};

export default DownloadShare;
