import React from 'react';
import styled from 'styled-components';

const DisplayContainer = styled.div`
  width: 300px;
  height: ${(300 * 3309) / 3308}px; /* Adjust height based on aspect ratio */
  position: relative;
  margin: 20px auto;
  border: ${(props) => (props.noBorder ? 'none' : '4px solid #000')};
  border-radius: ${(props) => (props.noBorder ? '0' : '10px')};
  box-shadow: ${(props) => (props.noShadow ? 'none' : '5px 5px 0 #000')};
  background-color: #fff;
  overflow: hidden;

  @media(min-width: 768px) {
    width: 400px;
    height: ${(400 * 3309) / 3308}px;
  }
`;

const Layer = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  /* Use 'object-fit: cover' to ensure the entire background is filled
     while maintaining aspect ratio, possibly cropping. If you prefer
     to see the entire image uncropped, you could use 'contain'. */
  object-fit: cover;
`;

const PFPDisplay = ({
  background,
  customBackground,
  accessory,
  baseBody,
  mouth,
  eyes,
  hat,
  hand,
  noBorder,
  noShadow,
}) => {
  return (
    <DisplayContainer id="pfp-display" noBorder={noBorder} noShadow={noShadow}>
      {/* Background Layer */}
      {background && background.name !== 'CUSTOM' && (
        <Layer src={background.image} alt="Background" />
      )}
      {background && background.name === 'CUSTOM' && customBackground && (
        <Layer src={customBackground} alt="Custom Background" />
      )}

      {/* Accessories */}
      {accessory && <Layer src={accessory.image} alt="Accessory" />}

      {/* Hat Back Part */}
      {hat?.isLinked && hat.backImage && (
        <Layer src={hat.backImage} alt={`${hat.name} Hat Back`} />
      )}

      {/* Base Body */}
      {baseBody && <Layer src={baseBody.image} alt="Base Body" />}

      {/* Eyes */}
      {eyes && <Layer src={eyes.image} alt="Eyes" />}

      {/* Mouth */}
      {mouth && <Layer src={mouth.image} alt="Mouth" />}

      {/* Hat Front or Single Hat */}
      {hat &&
        (hat.frontImage ? (
          <Layer src={hat.frontImage} alt={`${hat.name} Hat Front`} />
        ) : (
          <Layer src={hat.image} alt="Hat" />
        ))}

      {/* Hand */}
      {hand && <Layer src={hand.image} alt="Hand" />}
    </DisplayContainer>
  );
};

export default PFPDisplay;
